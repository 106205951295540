export default {
  "message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])};fn.source="hello i18n !!";return fn;})(),
  "Log in": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])};fn.source="Log in";return fn;})(),
  "believe": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We believe in equal acces to information for everyone"])};fn.source="We believe in equal acces to information for everyone";return fn;})(),
  "Free with AI": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free with AI"])};fn.source="Free with AI";return fn;})(),
  "Home": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])};fn.source="Home";return fn;})(),
  "Talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talents"])};fn.source="Talents";return fn;})(),
  "Pricing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pricing"])};fn.source="Pricing";return fn;})(),
  "Blogs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blogs"])};fn.source="Blogs";return fn;})(),
  "Contact": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])};fn.source="Contact";return fn;})(),
  "GO Platform All rights reserved": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO Platform All rights reserved"])};fn.source="GO Platform All rights reserved";return fn;})(),
  "Company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company"])};fn.source="Company";return fn;})(),
  "About us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About us"])};fn.source="About us";return fn;})(),
  "Contact us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us"])};fn.source="Contact us";return fn;})(),
  "For candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For candidates"])};fn.source="For candidates";return fn;})(),
  "Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jobs"])};fn.source="Jobs";return fn;})(),
  "Services": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])};fn.source="Services";return fn;})(),
  "Welcome to your next growth opportunity.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to your next growth opportunity."])};fn.source="Welcome to your next growth opportunity.";return fn;})(),
  "Get connected with full time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get connected with full time, freelance and remote jobs that are suited just for you and meet your prerequisite."])};fn.source="Get connected with full time, freelance and remote jobs that are suited just for you and meet your prerequisite.";return fn;})(),
  "APPLY AS A TALENT": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APPLY AS A TALENT"])};fn.source="APPLY AS A TALENT";return fn;})(),
  "We open you up to a tool of possibilities.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We open you up to a tool of possibilities."])};fn.source="We open you up to a tool of possibilities.";return fn;})(),
  "Erase completely the stress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erase completely the stress and uncertainty of applying directly to companies, through job boards and dealing with a lot of recruiters. GO PLATFORM will link you with opportunities you want and at companies with similar interests"])};fn.source="Erase completely the stress and uncertainty of applying directly to companies, through job boards and dealing with a lot of recruiters. GO PLATFORM will link you with opportunities you want and at companies with similar interests";return fn;})(),
  "Pass Test": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass Test"])};fn.source="Pass Test";return fn;})(),
  "Pass our test": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Pass our test and show companies what you can do, Actions are louder than words."])};fn.source=" Pass our test and show companies what you can do, Actions are louder than words.";return fn;})(),
  "Join Community": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Community"])};fn.source="Join Community";return fn;})(),
  "Improvement? No Problem!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Improvement? No Problem! We help you to learn new skills and improve your portfolio with none-compensated side projects."])};fn.source="Improvement? No Problem! We help you to learn new skills and improve your portfolio with none-compensated side projects.";return fn;})(),
  "Get Hired": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Hired"])};fn.source="Get Hired";return fn;})(),
  "Are one off gigs your preference?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are one off gigs your preference? GO PLATFORM helps you sharing your profile to companies in the U.S, Europe and the Gulf Region."])};fn.source="Are one off gigs your preference? GO PLATFORM helps you sharing your profile to companies in the U.S, Europe and the Gulf Region.";return fn;})(),
  "A One-Stop Platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A One-Stop Platform"])};fn.source="A One-Stop Platform";return fn;})(),
  "You connect with GO PLATFORM": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You connect with GO PLATFORM We communicate with dozens of companies, platforms and hundreds of recruiters to single out the best opportunity for you!"])};fn.source="You connect with GO PLATFORM We communicate with dozens of companies, platforms and hundreds of recruiters to single out the best opportunity for you!";return fn;})(),
  "Get Started": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Started"])};fn.source="Get Started";return fn;})(),
  "Talent Centric Process, Putting you FIRST!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent Centric Process, Putting you FIRST!"])};fn.source="Talent Centric Process, Putting you FIRST!";return fn;})(),
  "GO PLATFORM collaborates with companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GO PLATFORM collaborates with companies who uphold the International standard hiring process. We ensure that good and structured career growth experiences are adopted for you."])};fn.source="GO PLATFORM collaborates with companies who uphold the International standard hiring process. We ensure that good and structured career growth experiences are adopted for you.";return fn;})(),
  "Trusted by Talents across AFRICA!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trusted by Talents across AFRICA!"])};fn.source="Trusted by Talents across AFRICA!";return fn;})(),
  "“The consultants that reached out to me”": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["“The consultants that reached out to me about the role were super helpful, understanding and amazing. They were literally with me at every step from the interview to the waiting process and finally to getting the Job. Thank you Akorede and Oluwatobi.”"])};fn.source="“The consultants that reached out to me about the role were super helpful, understanding and amazing. They were literally with me at every step from the interview to the waiting process and finally to getting the Job. Thank you Akorede and Oluwatobi.”";return fn;})(),
  "Get matched to your dream opportunity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get matched to your dream opportunity"])};fn.source="Get matched to your dream opportunity";return fn;})(),
  "Save time and Focus your energy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save time and Focus your energy in getting one step closer to your next best opportunity. Join our talent network now!"])};fn.source="Save time and Focus your energy in getting one step closer to your next best opportunity. Join our talent network now!";return fn;})(),
  "Streamline your Recruitment. Start free.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streamline your Recruitment. Start free."])};fn.source="Streamline your Recruitment. Start free.";return fn;})(),
  "Choose the perfect plan for your business needs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the perfect plan for your business needs"])};fn.source="Choose the perfect plan for your business needs";return fn;})(),
  "Save 15%": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save 15%"])};fn.source="Save 15%";return fn;})(),
  "on monthly plan!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["on monthly plan!"])};fn.source="on monthly plan!";return fn;})(),
  "Monthly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monthly"])};fn.source="Monthly";return fn;})(),
  "Yearly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yearly"])};fn.source="Yearly";return fn;})(),
  "Free": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free"])};fn.source="Free";return fn;})(),
  "Best for personal use": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best for personal use"])};fn.source="Best for personal use";return fn;})(),
  "/month": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/month"])};fn.source="/month";return fn;})(),
  "What you get:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you get:"])};fn.source="What you get:";return fn;})(),
  "User": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User"])};fn.source="User";return fn;})(),
  "5 Tests per Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5 Tests per Assessment"])};fn.source="5 Tests per Assessment";return fn;})(),
  "Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments"])};fn.source="Assessments";return fn;})(),
  "Reporting and analytics": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reporting and analytics"])};fn.source="Reporting and analytics";return fn;})(),
  "Basic": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic"])};fn.source="Basic";return fn;})(),
  "Best for Small Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best for Small Business"])};fn.source="Best for Small Business";return fn;})(),
  "Upgraded features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgraded features:"])};fn.source="Upgraded features:";return fn;})(),
  "Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users"])};fn.source="Users";return fn;})(),
  "Customer Support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer Support"])};fn.source="Customer Support";return fn;})(),
  "Most Popular": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most Popular"])};fn.source="Most Popular";return fn;})(),
  "Pro": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])};fn.source="Pro";return fn;})(),
  "Best for Staffing Companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best for Staffing Companies"])};fn.source="Best for Staffing Companies";return fn;})(),
  "Evolved Features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolved Features:"])};fn.source="Evolved Features:";return fn;})(),
  "Talent Marketplace": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent Marketplace"])};fn.source="Talent Marketplace";return fn;})(),
  "Calender Integration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calender Integration"])};fn.source="Calender Integration";return fn;})(),
  "Enterprise": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enterprise"])};fn.source="Enterprise";return fn;})(),
  "Custom": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom"])};fn.source="Custom";return fn;})(),
  "Unlimited features:": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited features:"])};fn.source="Unlimited features:";return fn;})(),
  "Unlimited Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited Users"])};fn.source="Unlimited Users";return fn;})(),
  "Unlimited Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited Assessments"])};fn.source="Unlimited Assessments";return fn;})(),
  "Automation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automation"])};fn.source="Automation";return fn;})(),
  "Learn-more": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn-more"])};fn.source="Learn-more";return fn;})(),
  "Feature Table": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feature Table"])};fn.source="Feature Table";return fn;})(),
  "Unlimited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])};fn.source="Unlimited";return fn;})(),
  "Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment"])};fn.source="Assessment";return fn;})(),
  "Tests Per Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests Per Assessment"])};fn.source="Tests Per Assessment";return fn;})(),
  "Assessment Library": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment Library"])};fn.source="Assessment Library";return fn;})(),
  "Coding Languages": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coding Languages"])};fn.source="Coding Languages";return fn;})(),
  "Limited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited"])};fn.source="Limited";return fn;})(),
  "Flow": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flow"])};fn.source="Flow";return fn;})(),
  "Adds on": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adds on"])};fn.source="Adds on";return fn;})(),
  "Calendar Integration": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calendar Integration"])};fn.source="Calendar Integration";return fn;})(),
  "Assessment templates based on job roles": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessment templates based on job roles"])};fn.source="Assessment templates based on job roles";return fn;})(),
  "Corporate Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corporate Assessments"])};fn.source="Corporate Assessments";return fn;})(),
  "Anti-Cheat Measures": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anti-Cheat Measures"])};fn.source="Anti-Cheat Measures";return fn;})(),
  "Time Limits on Tests": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Limits on Tests"])};fn.source="Time Limits on Tests";return fn;})(),
  "IP Address Logging": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IP Address Logging"])};fn.source="IP Address Logging";return fn;})(),
  "Copy-Paste Disabled": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy-Paste Disabled"])};fn.source="Copy-Paste Disabled";return fn;})(),
  "Webcam Snapshots": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Webcam Snapshots"])};fn.source="Webcam Snapshots";return fn;})(),
  "Quality Control": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quality Control"])};fn.source="Quality Control";return fn;})(),
  "GDPR Compliance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GDPR Compliance"])};fn.source="GDPR Compliance";return fn;})(),
  "15 step": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 step process incl. peer reviews, sample testing, copywriters, review by psychometrician, and more"])};fn.source="15 step process incl. peer reviews, sample testing, copywriters, review by psychometrician, and more";return fn;})(),
  "To maintain test integrity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To maintain test integrity, GO PLATFORM has a proprietary item selection algorithm in place that includes item exposure controls to prevent any specific question from being exposed to more than 25% of candidates."])};fn.source="To maintain test integrity, GO PLATFORM has a proprietary item selection algorithm in place that includes item exposure controls to prevent any specific question from being exposed to more than 25% of candidates.";return fn;})(),
  "Dashboard": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])};fn.source="Dashboard";return fn;})(),
  "Library": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])};fn.source="Library";return fn;})(),
  "Activity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activity"])};fn.source="Activity";return fn;})(),
  "Manage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage"])};fn.source="Manage";return fn;})(),
  "Talent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talent"])};fn.source="Talent";return fn;})(),
  "Payouts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payouts"])};fn.source="Payouts";return fn;})(),
  "Schedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schedule"])};fn.source="Schedule";return fn;})(),
  "Soon": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soon"])};fn.source="Soon";return fn;})(),
  "New Project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Project"])};fn.source="New Project";return fn;})(),
  "You currently have no Project, you can create one above.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no Project, you can create one above."])};fn.source="You currently have no Project, you can create one above.";return fn;})(),
  "Archives": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archives"])};fn.source="Archives";return fn;})(),
  "New Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Assessment"])};fn.source="New Assessment";return fn;})(),
  "Confirm": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])};fn.source="Confirm";return fn;})(),
  "Clear All": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear All"])};fn.source="Clear All";return fn;})(),
  "Free trial": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Free trial"])};fn.source="Free trial";return fn;})(),
  "Refer a company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refer a company"])};fn.source="Refer a company";return fn;})(),
  "Discounts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply Discount"])};fn.source="Apply Discount";return fn;})(),
  "Get Now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Get Now"])};fn.source="Get Now";return fn;})(),
  "no code": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter a code"])};fn.source="Please enter a code";return fn;})(),
  "Terms": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])};fn.source="Terms";return fn;})(),
  "Privacy policy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy policy"])};fn.source="Privacy policy";return fn;})(),
  "hero-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" <span class='grad'> SKILL-BASED <img loading='lazy' src='/arch.svg'  class='arch' /></span  >recruitement  "])};fn.source=" <span class='grad'> SKILL-BASED <img loading='lazy' src='/arch.svg'  class='arch' /></span  >recruitement  ";return fn;})(),
  "hero-subtext": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Validate The Candidates Hard Skills, <br /> Soft Skills and Psychometrics."])};fn.source=" Validate The Candidates Hard Skills, <br /> Soft Skills and Psychometrics.";return fn;})(),
  "Start for free": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start for free"])};fn.source="Start for free";return fn;})(),
  "Inspired By": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inspired By"])};fn.source="Inspired By";return fn;})(),
  "Stop the Bleed from Unfilled Positions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stop the Bleed from Unfilled Positions"])};fn.source="Stop the Bleed from Unfilled Positions";return fn;})(),
  "All the tools that you need": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customizable assessments to reveal workforce readiness beyond resumes."])};fn.source="customizable assessments to reveal workforce readiness beyond resumes.";return fn;})(),
  "Accuracy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accuracy"])};fn.source="Accuracy";return fn;})(),
  "Precise & Valid Skills Data": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Precise & Valid Skills Data"])};fn.source="Precise & Valid Skills Data";return fn;})(),
  "Time Effectiveness": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time Effectiveness"])};fn.source="Time Effectiveness";return fn;})(),
  "Accelerated Hiring Velocity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accelerated Hiring Velocity"])};fn.source="Accelerated Hiring Velocity";return fn;})(),
  "COMMUNITY": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMUNITY"])};fn.source="COMMUNITY";return fn;})(),
  "help-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ready to join GO PLATFORM community?"])};fn.source="Ready to join GO PLATFORM community?";return fn;})(),
  "community-text": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The largest community of recruiter and conversational AI teams focused on sharing projects, benchmarks, best practices and creating the best assistants across every industry."])};fn.source="The largest community of recruiter and conversational AI teams focused on sharing projects, benchmarks, best practices and creating the best assistants across every industry.";return fn;})(),
  "Join Slack": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join Slack"])};fn.source="Join Slack";return fn;})(),
  "type to activate project": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["type <span> \" ", _interpolate(_named("projectName")), " \"</span> to ", _interpolate(_named("action")), " this project"])};fn.source="type <span> \" {projectName} \"</span> to {action} this project";return fn;})(),
  "Edit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])};fn.source="Edit";return fn;})(),
  "Project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project"])};fn.source="Project";return fn;})(),
  "Duplicate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duplicate"])};fn.source="Duplicate";return fn;})(),
  "Archive": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archive"])};fn.source="Archive";return fn;})(),
  "Activate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activate"])};fn.source="Activate";return fn;})(),
  "Delete": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete"])};fn.source="Delete";return fn;})(),
  "Cancel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])};fn.source="Cancel";return fn;})(),
  "type to delete project": (()=>{const fn=(ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["type <span> \" ", _interpolate(_named("projectName")), " \"</span> to delete this project"])};fn.source="type <span> \" {projectName} \"</span> to delete this project";return fn;})(),
  "invite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])};fn.source="Invite";return fn;})(),
  "Boards": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])};fn.source="Dashboard";return fn;})(),
  "Role": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])};fn.source="Role";return fn;})(),
  "Experience": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience"])};fn.source="Experience";return fn;})(),
  "Active": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])};fn.source="Active";return fn;})(),
  "Archived": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archived"])};fn.source="Archived";return fn;})(),
  "Time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Time"])};fn.source="Time";return fn;})(),
  "Status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])};fn.source="Status";return fn;})(),
  "Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates"])};fn.source="Candidates";return fn;})(),
  "Hide": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hide"])};fn.source="Hide";return fn;})(),
  "Click here": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click here"])};fn.source="Click here";return fn;})(),
  "To Compare Plans": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To Compare Plans"])};fn.source="To Compare Plans";return fn;})(),
  "Log in to your account": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])};fn.source="Log in";return fn;})(),
  "Password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])};fn.source="Password";return fn;})(),
  "Forgot Password?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forgot Password?"])};fn.source="Forgot Password?";return fn;})(),
  "Already have an account?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])};fn.source="Already have an account?";return fn;})(),
  "Sign Up": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign Up"])};fn.source="Sign Up";return fn;})(),
  "Email Address": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])};fn.source="Email Address";return fn;})(),
  "Next": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next"])};fn.source="Next";return fn;})(),
  "Don't have access to these informations?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't have access to these informations"])};fn.source="Don't have access to these informations";return fn;})(),
  "example@example.com": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["example&#64;example.com"])};fn.source="example&#64;example.com";return fn;})(),
  "Fill in your Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill in your Email"])};fn.source="Fill in your Email";return fn;})(),
  "Welcome to go platform!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to go platform!"])};fn.source="Welcome to go platform!";return fn;})(),
  "Thank you for choosing our app. We hope you enjoy using it.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you for choosing our app. We hope you enjoy using it."])};fn.source="Thank you for choosing our app. We hope you enjoy using it.";return fn;})(),
  "No Thanks.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Thanks."])};fn.source="No Thanks.";return fn;})(),
  "Start Tour": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Tour"])};fn.source="Start Tour";return fn;})(),
  "Online Users": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Online Users"])};fn.source="Online Users";return fn;})(),
  "Upgrade to PRO to get access to all features!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade to PRO to get access to all features!"])};fn.source="Upgrade to PRO to get access to all features!";return fn;})(),
  "Total Spent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Spent"])};fn.source="Total Spent";return fn;})(),
  "Learn More!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Learn More!"])};fn.source="Learn More!";return fn;})(),
  "Add now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
  "Search Title or Keyword": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Title or Keyword"])};fn.source="Search Title or Keyword";return fn;})(),
  "Job title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job title"])};fn.source="Job title";return fn;})(),
  "Seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seniority"])};fn.source="Seniority";return fn;})(),
  "Minimum Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Score"])};fn.source="Minimum Score";return fn;})(),
  "Select job seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select job seniority"])};fn.source="Select job seniority";return fn;})(),
  "Job Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Information"])};fn.source="Job Information";return fn;})(),
  "Select skills": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select skills"])};fn.source="Select skills";return fn;})(),
  "Don't show Salary?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Don't show Salary?"])};fn.source="Don't show Salary?";return fn;})(),
  "If you prefer, the job opening salary can also be kept private.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you prefer, the job opening salary can also be kept private."])};fn.source="If you prefer, the job opening salary can also be kept private.";return fn;})(),
  "Job Description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Description"])};fn.source="Job Description";return fn;})(),
  "Apply now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])};fn.source="Apply now";return fn;})(),
  "Total Tasks Done": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Tasks Done"])};fn.source="Total Tasks Done";return fn;})(),
  "Tasks Completed in last 7 Days": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tasks Completed in last 7 Days"])};fn.source="Tasks Completed in last 7 Days";return fn;})(),
  "Validate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validate"])};fn.source="Validate";return fn;})(),
  "Test Composition": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Test Composition"])};fn.source="Test Composition";return fn;})(),
  "30 multiple choice questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["30 multiple choice questions"])};fn.source="30 multiple choice questions";return fn;})(),
  "Tests included in the Assessment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tests included in the Assessment"])};fn.source="Tests included in the Assessment";return fn;})(),
  "Recent Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Candidates"])};fn.source="Recent Candidates";return fn;})(),
  "Invite": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite"])};fn.source="Invite";return fn;})(),
  "The Task title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Task title"])};fn.source="The Task title";return fn;})(),
  "Reviewers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reviewers"])};fn.source="Reviewers";return fn;})(),
  "Click to add reviewers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add reviewers"])};fn.source="Click to add reviewers";return fn;})(),
  "Add": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add"])};fn.source="Add";return fn;})(),
  "Labels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Labels"])};fn.source="Labels";return fn;})(),
  "Click to add labels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to add labels"])};fn.source="Click to add labels";return fn;})(),
  "Descriptions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descriptions"])};fn.source="Descriptions";return fn;})(),
  "Add a description": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a description"])};fn.source="Add a description";return fn;})(),
  "Attachment": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attachment"])};fn.source="Attachment";return fn;})(),
  "Remove": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])};fn.source="Remove";return fn;})(),
  "Uploaded at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded at"])};fn.source="Uploaded at";return fn;})(),
  "Click to attach a file": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to attach a file"])};fn.source="Click to attach a file";return fn;})(),
  "Comments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])};fn.source="Comments";return fn;})(),
  "Save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
  "Add a comment...": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a comment..."])};fn.source="Add a comment...";return fn;})(),
  "Add to cart": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add to cart"])};fn.source="Add to cart";return fn;})(),
  "Members": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Members"])};fn.source="Members";return fn;})(),
  "Checklist": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Checklist"])};fn.source="Checklist";return fn;})(),
  "Actions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])};fn.source="Actions";return fn;})(),
  "Copy": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy"])};fn.source="Copy";return fn;})(),
  "Move": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move"])};fn.source="Move";return fn;})(),
  "No notifications": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notifications"])};fn.source="No notifications";return fn;})(),
  "Control your Payments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control your Payments"])};fn.source="Control your Payments";return fn;})(),
  "Filters": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])};fn.source="Filters";return fn;})(),
  "Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])};fn.source="Name";return fn;})(),
  "Action": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])};fn.source="Action";return fn;})(),
  "at": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["at"])};fn.source="at";return fn;})(),
  "Find Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Jobs"])};fn.source="Find Jobs";return fn;})(),
  "Find Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find Candidates"])};fn.source="Find Candidates";return fn;})(),
  "Log out": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])};fn.source="Log out";return fn;})(),
  "Register Now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register Now"])};fn.source="Register Now";return fn;})(),
  "What role would you like to Hire?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What role would you like to Hire?"])};fn.source="What role would you like to Hire?";return fn;})(),
  "Developers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Developers"])};fn.source="Developers";return fn;})(),
  "Software Developers, Data Scientists, DevOps, and QA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Software Developers, Data Scientists, DevOps, and QA"])};fn.source="Software Developers, Data Scientists, DevOps, and QA";return fn;})(),
  "Designers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Designers"])};fn.source="Designers";return fn;})(),
  "Web, Mobile, UI/UX, Branding, and Visual Designers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web, Mobile, UI/UX, Branding, and Visual Designers"])};fn.source="Web, Mobile, UI/UX, Branding, and Visual Designers";return fn;})(),
  "Project Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Managers"])};fn.source="Project Managers";return fn;})(),
  "Digital Project Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Project Managers, IT Project Managers, Scrum Masters, and Agile Coaches"])};fn.source="Digital Project Managers, IT Project Managers, Scrum Masters, and Agile Coaches";return fn;})(),
  "Product Managers": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Managers"])};fn.source="Product Managers";return fn;})(),
  "Digital Product Managers, Product Owners, and Business Analysts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Digital Product Managers, Product Owners, and Business Analysts"])};fn.source="Digital Product Managers, Product Owners, and Business Analysts";return fn;})(),
  "Financial Experts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Experts"])};fn.source="Financial Experts";return fn;})(),
  "Financial Modelers, Fundraising Advisors, M&A and FP&A Experts": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financial Modelers, Fundraising Advisors, M&A and FP&A Experts"])};fn.source="Financial Modelers, Fundraising Advisors, M&A and FP&A Experts";return fn;})(),
  "What type of project are you hiring for?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What type of project are you hiring for?"])};fn.source="What type of project are you hiring for?";return fn;})(),
  "New idea or project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New idea or project"])};fn.source="New idea or project";return fn;})(),
  "Existing project that needs more resources": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Existing project that needs more resources"])};fn.source="Existing project that needs more resources";return fn;})(),
  "Ongoing assistance or consultation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongoing assistance or consultation"])};fn.source="Ongoing assistance or consultation";return fn;})(),
  "None of the above, I'm just looking to learn more about RECRUITABLE.": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["None of the above, I'm just looking to learn more about RECRUITABLE."])};fn.source="None of the above, I'm just looking to learn more about RECRUITABLE.";return fn;})(),
  "How long do you need the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How long do you need the"])};fn.source="How long do you need the";return fn;})(),
  "for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for"])};fn.source="for";return fn;})(),
  "Less than 1 week": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 1 week"])};fn.source="Less than 1 week";return fn;})(),
  "1 to 4 weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 to 4 weeks"])};fn.source="1 to 4 weeks";return fn;})(),
  "1 to 3 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 to 3 months"])};fn.source="1 to 3 months";return fn;})(),
  "3 to 6 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 to 6 months"])};fn.source="3 to 6 months";return fn;})(),
  "Longer than 6 months": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longer than 6 months"])};fn.source="Longer than 6 months";return fn;})(),
  "I'll decide later": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'll decide later"])};fn.source="I'll decide later";return fn;})(),
  "How many": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many"])};fn.source="How many";return fn;})(),
  "do you need": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["do you need"])};fn.source="do you need";return fn;})(),
  "One": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One"])};fn.source="One";return fn;})(),
  "Multiple": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple"])};fn.source="Multiple";return fn;})(),
  "A cross-functional team (developers, designers, project managers)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A cross-functional team (developers, designers, project managers)"])};fn.source="A cross-functional team (developers, designers, project managers)";return fn;})(),
  "What level of time commitment will you require from the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What level of time commitment will you require from the"])};fn.source="What level of time commitment will you require from the";return fn;})(),
  "Fulltime (40 or more hrs/week)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fulltime (40 or more hrs/week)"])};fn.source="Fulltime (40 or more hrs/week)";return fn;})(),
  "Part time (Less than 40 hrs/week)": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Part time (Less than 40 hrs/week)"])};fn.source="Part time (Less than 40 hrs/week)";return fn;})(),
  "Hourly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hourly"])};fn.source="Hourly";return fn;})(),
  "What skills would you like to see in your new Hire?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What skills would you like to see in your new Hire?"])};fn.source="What skills would you like to see in your new Hire?";return fn;})(),
  "Popular skills for": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popular skills for"])};fn.source="Popular skills for";return fn;})(),
  "How many people are employed at your company?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How many people are employed at your company?"])};fn.source="How many people are employed at your company?";return fn;})(),
  "Less than 10": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Less than 10"])};fn.source="Less than 10";return fn;})(),
  "More than 1000": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 1000"])};fn.source="More than 1000";return fn;})(),
  "When do you need the": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["When do you need the"])};fn.source="When do you need the";return fn;})(),
  "to start?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to start?"])};fn.source="to start?";return fn;})(),
  "Immediately": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immediately"])};fn.source="Immediately";return fn;})(),
  "In 1 to 2 weeks": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In 1 to 2 weeks"])};fn.source="In 1 to 2 weeks";return fn;})(),
  "More than 2 weeks form now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More than 2 weeks form now"])};fn.source="More than 2 weeks form now";return fn;})(),
  "Are you open to working with a remote": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you open to working with a remote"])};fn.source="Are you open to working with a remote";return fn;})(),
  "Yes": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])};fn.source="Yes";return fn;})(),
  "No": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])};fn.source="No";return fn;})(),
  "I'm not sure yet": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I'm not sure yet"])};fn.source="I'm not sure yet";return fn;})(),
  "Success! Let's connect you with GO platform": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success! Let's connect you with GO platform"])};fn.source="Success! Let's connect you with GO platform";return fn;})(),
  "Company's name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company's name"])};fn.source="Company's name";return fn;})(),
  "Contact's name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact's name"])};fn.source="Contact's name";return fn;})(),
  "Back": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])};fn.source="Back";return fn;})(),
  "Continue": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])};fn.source="Continue";return fn;})(),
  "to start": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to start"])};fn.source="to start";return fn;})(),
  "Latest Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest Jobs"])};fn.source="Latest Jobs";return fn;})(),
  "All Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Jobs"])};fn.source="All Jobs";return fn;})(),
  "Categories": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])};fn.source="Categories";return fn;})(),
  "Development": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Development"])};fn.source="Development";return fn;})(),
  "Featured Companies": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured Companies"])};fn.source="Featured Companies";return fn;})(),
  "Settings": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])};fn.source="Settings";return fn;})(),
  "Profile": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])};fn.source="Profile";return fn;})(),
  "Applicant dropout": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant dropout"])};fn.source="Applicant dropout";return fn;})(),
  "Spent this mount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spent this mount"])};fn.source="Spent this mount";return fn;})(),
  "Applicant revival": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicant revival"])};fn.source="Applicant revival";return fn;})(),
  "AVG spent per hire": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVG spent per hire"])};fn.source="AVG spent per hire";return fn;})(),
  "Recent": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent"])};fn.source="Recent";return fn;})(),
  "Any questions?": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions,"])};fn.source="Questions,";return fn;})(),
  "Write or call us. We will write back within 12h": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suggestion Or Even Say Hi? Our Doors Are Always Open :)"])};fn.source="Suggestion Or Even Say Hi? Our Doors Are Always Open :)";return fn;})(),
  "Report a bug": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Report a bug"])};fn.source="Report a bug";return fn;})(),
  "Others": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Others"])};fn.source="Others";return fn;})(),
  "Feedback": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])};fn.source="Feedback";return fn;})(),
  "Request": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])};fn.source="Request";return fn;})(),
  "Partnership": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partnership"])};fn.source="Partnership";return fn;})(),
  "Urgency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urgency"])};fn.source="Urgency";return fn;})(),
  "Send": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])};fn.source="Send";return fn;})(),
  "Individual Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individual Score"])};fn.source="Individual Score";return fn;})(),
  "Pending": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pending"])};fn.source="Pending";return fn;})(),
  "Position": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position"])};fn.source="Position";return fn;})(),
  "Invite Co-worker": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite Co-worker"])};fn.source="Invite Co-worker";return fn;})(),
  "Candidate email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate email"])};fn.source="Candidate email";return fn;})(),
  "apply now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply now"])};fn.source="Apply now";return fn;})(),
  "Company Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])};fn.source="Company Information";return fn;})(),
  "Company Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])};fn.source="Company name";return fn;})(),
  "select employees count": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select employees count"])};fn.source="Select employees count";return fn;})(),
  "Company Website": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Website"])};fn.source="Company Website";return fn;})(),
  "select company's industry": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select company's industry"])};fn.source="Select company's industry";return fn;})(),
  "Company Logo": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Logo"])};fn.source="Company Logo";return fn;})(),
  "Company Logo should be a PNG or JPG file of 250 x 250 pixels": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Logo should be a PNG or JPG file of 250 x 250 pixels"])};fn.source="Company Logo should be a PNG or JPG file of 250 x 250 pixels";return fn;})(),
  "Recruiter Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recruiter Information"])};fn.source="Recruiter Information";return fn;})(),
  "Featured Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Featured Jobs"])};fn.source="Featured Jobs";return fn;})(),
  "Assess a": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assess a"])};fn.source="Assess a";return fn;})(),
  "Form Steps": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Form Steps"])};fn.source="Form Steps";return fn;})(),
  "First Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First Name"])};fn.source="First Name";return fn;})(),
  "Last name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])};fn.source="Last name";return fn;})(),
  "Confirm password": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm password"])};fn.source="Confirm password";return fn;})(),
  "If you purchased a featured job, please insert your order ID below": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you purchased a featured job, please insert your order ID below"])};fn.source="If you purchased a featured job, please insert your order ID below";return fn;})(),
  "Previous": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous"])};fn.source="Previous";return fn;})(),
  "Submit": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Submit"])};fn.source="Submit";return fn;})(),
  "registerFormDescription": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To submit an assessment test for approval, please fill the following form. If you have any questions in the process, please reach out to  <a class='blue_span'> hi&#64;go-platform.dz</a> <br />If you are looking to post a featured job, please purchase a <a class='blue_span'> featured package here</a>. After that, please add your order ID in the form at the Featured Job section."])};fn.source="To submit an assessment test for approval, please fill the following form. If you have any questions in the process, please reach out to  <a class='blue_span'> hi&#64;go-platform.dz</a> <br />If you are looking to post a featured job, please purchase a <a class='blue_span'> featured package here</a>. After that, please add your order ID in the form at the Featured Job section.";return fn;})(),
  "Invoice": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice"])};fn.source="Invoice";return fn;})(),
  "Pricing Plans": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plans"])};fn.source="Plans";return fn;})(),
  "Invoice ID": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice ID"])};fn.source="Invoice ID";return fn;})(),
  "Download as PDF": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download as PDF"])};fn.source="Download as PDF";return fn;})(),
  "Print": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Print"])};fn.source="Print";return fn;})(),
  "Recieve Payements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receive Payments"])};fn.source="Receive Payments";return fn;})(),
  "Invoice to": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])};fn.source="To";return fn;})(),
  "Invoice No": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice n&#176;"])};fn.source="Invoice n&#176;";return fn;})(),
  "Order Num": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order n&#176;"])};fn.source="Order n&#176;";return fn;})(),
  "Invoice Date": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invoice Date"])};fn.source="Invoice Date";return fn;})(),
  "Payement Due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment Due"])};fn.source="Payment Due";return fn;})(),
  "Amount Due": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount Due"])};fn.source="Amount Due";return fn;})(),
  "Quantity": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantity"])};fn.source="Quantity";return fn;})(),
  "Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rate"])};fn.source="Rate";return fn;})(),
  "Amount": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])};fn.source="Amount";return fn;})(),
  "Sub Total": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sub Total"])};fn.source="Sub Total";return fn;})(),
  "Tax": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tax"])};fn.source="Tax";return fn;})(),
  "My Jobs": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Jobs"])};fn.source="My Jobs";return fn;})(),
  "Post Job": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post Job"])};fn.source="Post Job";return fn;})(),
  "No available jobs for now": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available jobs for now"])};fn.source="No available jobs for now";return fn;})(),
  "Refferal": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referal"])};fn.source="Referal";return fn;})(),
  "Completion": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Completion"])};fn.source="Completion";return fn;})(),
  "Applicants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicants"])};fn.source="Applicants";return fn;})(),
  "Assessements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments"])};fn.source="Assessments";return fn;})(),
  "Success Rate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Rate"])};fn.source="Success Rate";return fn;})(),
  "On Track": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["On Track"])};fn.source="On Track";return fn;})(),
  "Daily": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daily"])};fn.source="Daily";return fn;})(),
  "Weekly": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weekly"])};fn.source="Weekly";return fn;})(),
  "Avg Completion Time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avg Completion Time"])};fn.source="Avg Completion Time";return fn;})(),
  "Behind Schedule": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Behind Schedule"])};fn.source="Behind Schedule";return fn;})(),
  "Recent Applicants": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recent Applicants"])};fn.source="Recent Applicants";return fn;})(),
  "View All": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View All"])};fn.source="View All";return fn;})(),
  "send invitations": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send invitations"])};fn.source="Send invitations";return fn;})(),
  "Close": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])};fn.source="Close";return fn;})(),
  "invite team members": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite team members"])};fn.source="Invite team members";return fn;})(),
  "My Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Assessments"])};fn.source="My Assessments";return fn;})(),
  "Invite a friend": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite a friend"])};fn.source="Invite a friend";return fn;})(),
  "you both get": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["you both get"])};fn.source="you both get";return fn;})(),
  "Invite your friends": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite your friends and start working together in seconds. Everyone you invite will receive a welcome email"])};fn.source="Invite your friends and start working together in seconds. Everyone you invite will receive a welcome email";return fn;})(),
  "Email Adress": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email Address"])};fn.source="Email Address";return fn;})(),
  "Send Invitation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Invitation"])};fn.source="Send Invitation";return fn;})(),
  "More ways to invite your friends": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More ways to invite your friends"])};fn.source="More ways to invite your friends";return fn;})(),
  "Invite with Gmail": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite with Gmail"])};fn.source="Invite with Gmail";return fn;})(),
  "Copied": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied"])};fn.source="Copied";return fn;})(),
  "Copy Link": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copy link"])};fn.source="Copy link";return fn;})(),
  "Share on LinkedIn": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share on LinkedIn"])};fn.source="Share on LinkedIn";return fn;})(),
  "RefferalViewBottomText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: <font-awesome-icon :icon=\"['fas', 'circle-info']\" /> Once you’ve invited friends, you can <a href=''> view the status</a> of your referrals or visit our <a href=''> Help Center</a> if you have any questions."])};fn.source="Note: <font-awesome-icon :icon=\"['fas', 'circle-info']\" /> Once you’ve invited friends, you can <a href=''> view the status</a> of your referrals or visit our <a href=''> Help Center</a> if you have any questions.";return fn;})(),
  "Candidate": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidate"])};fn.source="Candidate";return fn;})(),
  "Set Success Score": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Success Score"])};fn.source="Success Score";return fn;})(),
  "Selected Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selected Assessments"])};fn.source="Selected Assessments";return fn;})(),
  "Select": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select"])};fn.source="Select";return fn;})(),
  "Destribution by response time": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution by response time"])};fn.source="Distribution by response time";return fn;})(),
  "This chart represents the time of candidates to pass the test after it was sent to them": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This chart represents the time of candidates to pass the test after it was sent to them"])};fn.source="This chart represents the time of candidates to pass the test after it was sent to them";return fn;})(),
  "Edit project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit project"])};fn.source="Edit project";return fn;})(),
  "Project name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project name"])};fn.source="Project name";return fn;})(),
  "Job Title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job Title"])};fn.source="Job Title";return fn;})(),
  "select job seniority": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select job seniority"])};fn.source="Select job seniority";return fn;})(),
  "Edit assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit assessments"])};fn.source="Edit assessments";return fn;})(),
  "save": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])};fn.source="Save";return fn;})(),
  "Design": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])};fn.source="Design";return fn;})(),
  "Marketing": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marketing"])};fn.source="Marketing";return fn;})(),
  "Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Business"])};fn.source="Business";return fn;})(),
  "Support": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])};fn.source="Support";return fn;})(),
  "Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])};fn.source="Email";return fn;})(),
  "Already have an account? ": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Already have an account?"])};fn.source="Already have an account?";return fn;})(),
  "ompany Information": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company Information"])};fn.source="Company Information";return fn;})(),
  "Company name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company name"])};fn.source="Company name";return fn;})(),
  "Company website": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Company website"])};fn.source="Company website";return fn;})(),
  "Read more!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Read more!"])};fn.source="Read more!";return fn;})(),
  "Discover More Insightful Articles": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover More Insightful Articles"])};fn.source="Discover More Insightful Articles";return fn;})(),
  "The GO PLATFORM community": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The GO PLATFORM community"])};fn.source="The GO PLATFORM community";return fn;})(),
  "Join us": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Join us"])};fn.source="Join us";return fn;})(),
  "Talent email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email"])};fn.source="Enter your email";return fn;})(),
  "talent invitation": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])};fn.source="Send";return fn;})(),
  "Your Name": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Name"])};fn.source="Your Name";return fn;})(),
  "Your Email": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Email"])};fn.source="Your Email";return fn;})(),
  "Filter by project": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a project"])};fn.source="Select a project";return fn;})(),
  "Filter by status": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidates status"])};fn.source="Candidates status";return fn;})(),
  "passed": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passed"])};fn.source="passed";return fn;})(),
  "invited": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invited"])};fn.source="invited";return fn;})(),
  "Payements": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payments"])};fn.source="Payments";return fn;})(),
  "manage": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["manage"])};fn.source="manage";return fn;})(),
  "Credit cards": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Credit cards"])};fn.source="Credit cards";return fn;})(),
  "Nme/Business": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nme/Business"])};fn.source="Nme/Business";return fn;})(),
  "Type": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])};fn.source="Type";return fn;})(),
  "Number": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number"])};fn.source="Number";return fn;})(),
  "Expire": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire"])};fn.source="Expire";return fn;})(),
  "Send Message": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send Message"])};fn.source="Send Message";return fn;})(),
  "employees": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["employees"])};fn.source="employees";return fn;})(),
  "Overview": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overview"])};fn.source="Overview";return fn;})(),
  "Benefits": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benefits"])};fn.source="Benefits";return fn;})(),
  "Add a new card": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a new card"])};fn.source="Add a new card";return fn;})(),
  "Team": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Team"])};fn.source="Team";return fn;})(),
  "Role Category": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role Category"])};fn.source="Role Category";return fn;})(),
  "Compensation & Benefit Consultant": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compensation & Benefit Consultant"])};fn.source="Compensation & Benefit Consultant";return fn;})(),
  "Experience Requirement": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience Requirement"])};fn.source="Experience Requirement";return fn;})(),
  "Finance": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])};fn.source="Finance";return fn;})(),
  "Working Location": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Working Location"])};fn.source="Working Location";return fn;})(),
  "Jakerta": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jakerta"])};fn.source="Jakerta";return fn;})(),
  "Easy Apply": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Easy Apply"])};fn.source="Easy Apply";return fn;})(),
  "Our company": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our company"])};fn.source="Our company";return fn;})(),
  "Add photos": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add photos"])};fn.source="Add photos";return fn;})(),
  "More": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["More"])};fn.source="More";return fn;})(),
  "Invited recruiter": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invited recruiter"])};fn.source="Invited recruiter";return fn;})(),
  "Select a project, and assess your talents!": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Invite Talents to pass an Assessment, or Request help on our"])};fn.source="Try Invite Talents to pass an Assessment, or Request help on our";return fn;})(),
  "Slack Channel": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slack Channel."])};fn.source="Slack Channel.";return fn;})(),
  "Top Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top Assessments"])};fn.source="Top Assessments";return fn;})(),
  "Search Assessment, Job Position": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Assessment, Job Position"])};fn.source="Search Assessment, Job Position";return fn;})(),
  "accuracyText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our spot-on evaluations go beyond testing knowledge to assess real-world skills and workplace readiness."])};fn.source="Our spot-on evaluations go beyond testing knowledge to assess real-world skills and workplace readiness.";return fn;})(),
  "timeEffectivenessText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hire faster with our customizable skills assessments. Identify role-specific competencies, streamline your hiring process, and make data-driven candidate selections."])};fn.source="Hire faster with our customizable skills assessments. Identify role-specific competencies, streamline your hiring process, and make data-driven candidate selections.";return fn;})(),
  "Cost Efficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost Efficiency"])};fn.source="Cost Efficiency";return fn;})(),
  "costEficiencyText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fill roles faster and save money. Our validated skills assessments quickly identify best-fit talent so you avoid expensive vacancies and mis-hires."])};fn.source="Fill roles faster and save money. Our validated skills assessments quickly identify best-fit talent so you avoid expensive vacancies and mis-hires.";return fn;})(),
  "contactText": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Just let us know how we can help you and our team will reach out to you in less than 24h."])};fn.source="Just let us know how we can help you and our team will reach out to you in less than 24h.";return fn;})(),
  "Select a title": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select a title"])};fn.source="Select a title";return fn;})(),
  "NO DATA": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data"])};fn.source="No Data";return fn;})(),
  "Hire Squad": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hire Squad"])};fn.source="Hire Squad";return fn;})(),
  "Search Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search Assessment."])};fn.source="Search Assessment.";return fn;})(),
  "No Candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You currently have no assessed Talents in this Project,\n  Start new Project and Invite Talents to pass your assessment."])};fn.source="You currently have no assessed Talents in this Project,\n  Start new Project and Invite Talents to pass your assessment.";return fn;})(),
  "Insights": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insights"])};fn.source="Insights";return fn;})(),
  "Assessments": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assessments"])};fn.source="Assessments";return fn;})(),
  "Struggling talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Struggling talents"])};fn.source="Struggling talents";return fn;})(),
  "Skilled talents": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skilled talents"])};fn.source="Skilled talents";return fn;})(),
  "lack of proficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lack of proficiency"])};fn.source="Lack of proficiency";return fn;})(),
  "HIgh level of proficiency": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High level of proficiency"])};fn.source="High level of proficiency";return fn;})(),
  "Number of questions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions per test"])};fn.source="Questions per test";return fn;})(),
  "Register": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Register"])};fn.source="Register";return fn;})(),
  "Let's get to know you better": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let's get to know you better..."])};fn.source="Let's get to know you better...";return fn;})(),
  "Invite candidates": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invite candidates"])};fn.source="Invite candidates";return fn;})(),
  "Clear List": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear List"])};fn.source="Clear List";return fn;})(),
  "This preview mode is not interactive, however, the assessment your candidate will see is": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This preview mode is not interactive, however, the assessment your candidate will see is"])};fn.source="This preview mode is not interactive, however, the assessment your candidate will see is";return fn;})(),
  "project name tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter a name that best describes this assessment."])};fn.source="Enter a name that best describes this assessment.";return fn;})(),
  "category tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the category of the job that you're hiring for, so we can filter the jobs for you."])};fn.source="Select the category of the job that you're hiring for, so we can filter the jobs for you.";return fn;})(),
  "job position tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the job role that most closely matches the one you're hiring for, so we can suggest the right tests for you."])};fn.source="Choose the job role that most closely matches the one you're hiring for, so we can suggest the right tests for you.";return fn;})(),
  "job seniority tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select the seniority level of the job role you're hiring for."])};fn.source="Select the seniority level of the job role you're hiring for.";return fn;})(),
  "Upgrade": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upgrade"])};fn.source="Upgrade";return fn;})(),
  "created by tooltip": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can use an assessment template created by our experts that includes the right selection of tests and custom questions to get the best hires, or build your own assessment."])};fn.source="You can use an assessment template created by our experts that includes the right selection of tests and custom questions to get the best hires, or build your own assessment.";return fn;})(),
  "Job-Positions": (()=>{const fn=(ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Job-Positions"])};fn.source="Job-Positions";return fn;})()
}